import 'react-app-polyfill/ie9'; // For IE 9-11 support
import './polyfill';

import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import * as serviceWorker from './serviceWorker';
import { createTheme, ThemeProvider } from '@mui/material/styles';
//SOPORTE PARA TRADUCCIÓN
import { IntlProvider } from 'react-intl';
import translation_es_MX from './i18n/es-MX';
import translation_en_US from './i18n/en-US';
import countries from './modules/utils/countries';
import RequestManager from './modules/utils/RequestManager';
import { ENVIRONMENT, ENVIRONMENTS, GA_DEV_KEY, GA_SAND_KEY, GA_PROD_KEY, GTM_PROD, GTM_SAND } from './env';
//import ReactGA from 'react-ga';
import TagManager from 'react-gtm-module';

const tagManagerArgs = {
	gtmId: '',
};

console.log('Initializing GA account ...');
switch (ENVIRONMENT) {
	case ENVIRONMENTS.DEV:
		//ReactGA.initialize(GA_DEV_KEY);
		tagManagerArgs.gtmId = GTM_SAND;
		break;
	case ENVIRONMENTS.SAND:
		//ReactGA.initialize(GA_SAND_KEY);
		tagManagerArgs.gtmId = GTM_SAND;
		break;
	case ENVIRONMENTS.PROD:
		//ReactGA.initialize(GA_PROD_KEY);
		tagManagerArgs.gtmId = GTM_PROD;
		break;
	default:
		//ReactGA.initialize('UA-170662568-1');
		tagManagerArgs.gtmId = GTM_SAND;
}
//ReactGA.pageview(window.location.pathname + window.location.search);

TagManager.initialize(tagManagerArgs);

function setTranslations() {
	const initialTranslations = {
		es: translation_es_MX,
		en: translation_en_US,
	};
	// const initialTranslations = {
	//   'es': {},
	//   'en': {}
	// };
	const languageNav = navigator.language.split(/[-_]/)[0];
	const languageStorage = localStorage.getItem('language');
	let language = '';
	let country = '';
	//Si el usuario tiene establecido un idioma en el local storage se utiliza ese
	if (languageStorage && initialTranslations[languageStorage]) {
		language = languageStorage;
		if (language === countries.MX.language) {
			country = countries.MX.code;
		} else if (language === countries.US.language) {
			country = countries.US.code;
		}
		localStorage.setItem('country', country);
	}
	//Si no, se utiliza el del navegador
	else if (languageNav && initialTranslations[languageNav]) {
		language = languageNav;
		if (language === countries.MX.language) {
			country = countries.MX.code;
		} else if (language === countries.US.language) {
			country = countries.US.code;
		}
		localStorage.setItem('country', country);
		localStorage.setItem('language', language);
	}
	//Español por defecto si no hay otros
	else {
		language = countries.MX.language;
		country = countries.MX.code;
		localStorage.setItem('language', language);
		localStorage.setItem('country', country);
	}
	loadTranslations(language, initialTranslations);
}

function loadTranslations(language, initialTranslations) {
	let request = {
		serviceName: 'translations',
		urlParams: {
			lang: language,
		},
	};
	//Load booking translations
	RequestManager.callIbeService(
		request,
		(data) => {
			let translations = { ...initialTranslations[language], ...data };

			let cmsTranslations = {
				serviceName: 'i18',
				urlParams: {
					lang: language,
				},
			};
			//Load CMS translations
			RequestManager.callCmsService(
				cmsTranslations,
				(trans) => {
					translations = { ...translations, ...trans };
					render(language, translations);
				},
				(error) => {
					render(language, translations);
					console.log(Error('Error to load translations from server'));
					console.log(error);
				}
			);
		},
		(error) => {
			render(language, initialTranslations[language]);
			console.log(Error('Error to load translations from server'));
			console.log(error);
		}
	);
}

const theme = createTheme({
	palette: {
		primary: {
			main: '#416072',
		},
		secondary: {
			main: '#e51f04',
		},
		tarGrayDark: {
			main: '#9B9B9B',
		},
		tarGrayBox: {
			main: '#F6F6F6',
		},
		white: {
			main: '#FFFFFF',
		},
		contrastThreshold: 3,
		tonalOffset: 0.2,
	},
	typography: {
		fontFamily: ['Montserrat', '-apple-system', 'sans-serif'].join(','),
	},
});

function render(language, translations) {
	ReactDOM.render(
		<IntlProvider locale={language} messages={translations}>
			<ThemeProvider theme={theme}>
				<App />
			</ThemeProvider>
		</IntlProvider>,
		document.getElementById('root')
	);
}

setTranslations();

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
