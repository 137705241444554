// Country Catalog (ISO 3166-1)

export default {
    MX: {
        name: 'México',
        code: 'MX',
        code3: 'MEX',
        currency: 'MXN',
        currency_simbol: '$',
        phone: '+52',
        language: 'es',
        flag: 'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACUAAAAWCAMAAABwrqUyAAAACXBIWXMAAAsTAAALEwEAmpwYAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAADbUExURa8AMCxoSLsAMMtAZP///7wAMMxAZP///yxpSVA3KF07KF4/K2A+KGBGMWeHdmxBKG1WSnxZQohfP4u4zY2CZZaysJi3tZtzS6SxtqaIcaqCWqvDtqvDw6yEWrK5o7WlnrWuh7WykLmVb7qkobqomrqxrLrJubwAMMTBu8TIqsWwn8iYcsm3scxAZM/NqNDTutHTvdO8cdTLptjGoNjVs9jYw9vPxNvbyNvdzd/XtODi0+PgyOTl2uXXzuXn2eXq4eXs6Obl0err3+7t3u/x6fbw6fn48/779P///wMAolcAAAAIdFJOUxBAQEBA/v7+LIhRGQAAAH5JREFUKM9j4EACDh4IoKuOAEwMw0yVq5YZQVVOxlJCnCqEVLkbKfHz8tgSUGVuKc4tKSiPV5WNtY6hLJeAqoYmPlVuBqISMgoiyhbOeG20chSW4xNTMyXgLn09RW1pExdC4WVn72E17NMEGLCzs7EiABMDIxJgQQLMTAjAAABF1Wuxb1qGZwAAAABJRU5ErkJggg=='
    },
    US: {
        name: 'United States of America',
        code: 'US',
        code3: 'USA',
        currency: 'USD',
        currency_simbol: '$',
        phone: '+1',
        language: 'en',
        flag: 'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACAAAAAWCAYAAAChWZ5EAAABT0lEQVRIS2PMFlaJ3SwbPO8dqwgLAwgwM4Ephr//IDQRfCYONobqI7kQ9cSDl///M6QyyhmU/37LKAixnEzAyM7KUHummBzdLxl5TLv////zl2HnvBQGRkYGhpiGC2CDFtcbEM1nZmNhODXXgiQHTJm3FKwe7oBd81MYGBkYGKKhDljSYEA0n5mVheHUPHIdYNH7///P3wxi8iYk+xzmZVZudoZtpyJJCoG1959AQwDNAaT4HGYjGzc7w1ayHTCaBkbTwGgaQEsDJGVmqGKKygE+h8n///34xSAhZ8zA8J+BgYmNGVwC/v39l2g+Oy8Hw/q9gSS5HV4QNU2c+5+dnY0hNTqUJAMoVQyvCwbcAZ0soIBnYAhWlKHUUyTph0fBgDtgwKNgwB3QycLwgoGBQZykCKSe4meMHcwMvoyMDHMYGBjEqGcuUSY9+/+fIQMA7YEh8yRPGe0AAAAASUVORK5CYII='
    }
};

