import React, {Component} from 'react';
import { HashRouter as Router, Route } from 'react-router-dom';
import { AdapterMoment } from '@mui/x-date-pickers-pro/AdapterMoment';
import { LocalizationProvider } from '@mui/x-date-pickers-pro';
import moment from "moment";
import "moment/locale/es";

//Import FontAwesome Icons
import { library } from '@fortawesome/fontawesome-svg-core';
import { fas } from '@fortawesome/free-solid-svg-icons';
import { fab } from '@fortawesome/free-brands-svg-icons';

//Import general styles
import './assets/style/css/index.css';
import './assets/style/scss/App.scss';
import './assets/style/less/App.less';

import Layout from './modules/layouts/ResponsiveLayout';
import LoadingModal from './modules/components/Commons/LoadingModal';

import {MUI_PRO_KEY} from './env'

import { LicenseInfo } from '@mui/x-date-pickers-pro';
LicenseInfo.setLicenseKey(MUI_PRO_KEY);

//Load commons icons
library.add(fas, fab);

class App extends Component {

    constructor(props) {
        super(props);
        this.state = {
            loaded: false
        };
    }

    componentDidMount() {
        //Load catalogs
        this.setState({
            loaded: true
        });
    }
  
    render () {
        const lang = localStorage.getItem("language");
        //Set moment locale
        moment.locale(lang);
        if(!this.state.loaded) {
            return (
                <LoadingModal loading={true} />
            );
        }
        else {
            return (
                <LocalizationProvider dateAdapter={AdapterMoment} adapterLocale={lang}>
					<Router>
					    <Route path='/' name='Home' render={(props) => <Layout props={props} />} />
				    </Router>
				</LocalizationProvider>
            );
        }
    }
}

export default App;
